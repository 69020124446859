.headerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8% 0 0 0;
}

.switchWrapper {
  display: flex;
}

.themeLabel {
  font-size: 64px;
  text-transform: uppercase;
}

.art {
  font-family: 'Caveat';
  color: #3e5fd4;
}

.code {
  font-family: 'Iceberg';
  color: #e7e7e7;
}

.subTitle {
  font-family: 'Montserrat';
  font-size: 20px;
  margin: 30px 0 0 -40px;
}

.link {
  text-decoration: none;
}

#blocker {
  visibility: hidden;
}

/* mobile view */
@media (max-width: 800px) {
  .switchWrapper {
    flex-direction: column;
    align-items: center;
  }

  .themeLabel {
    font-size: 40px;
  }

  .subTitle {
    font-size: 16px;
    margin: 10px 0 0 0;
  }
}
