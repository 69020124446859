.routeWrapper {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 80%;
  margin-top: 30px;
}

/* mobile view */
@media (max-width: 800px) {
  .routeWrapper {
    width: 90%;
  }
}
