.wrapper {
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}

.card {
  width: 100%;
  height: auto;
}

.layover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 4px);
  color: #e7e7e7;
  background-color: rgba(0, 27, 124, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.wrapper:hover > .layover {
  opacity: 1;
}

.layover h3 {
  position: inherit;
  width: 100%;
  padding: 20px;
  bottom: 0px;
  text-align: right;
}

.fullsizeWrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 8;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.imageWrapper img {
  height: 80vh;
  width: auto;
  z-index: 9;
}

.imageWrapper p {
  color: white;
  z-index: 10;
}

.fullsizeBackground {
  position: absolute;
  background-color: rgba(0, 11, 54, 0.8);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 40px;
  cursor: pointer;
  color: white;
}
