/* import google fonts */
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&family=Iceberg&family=Montserrat:ital,wght@0,400;0,600;1,400&display=swap');

/* reset browser settings */
html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}


body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

h1,
h2,
h3 {
  font-weight: 700;
}

h1 {
  font-size: 20px;
}
h2 {
  font-size: 18px;
}
h3 {
  font-size: 14px;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
